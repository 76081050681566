import type { ComponentType } from 'react';

// prettier-ignore
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mountableReactPages: Record<string, () => Promise<{ default: ComponentType<React.PropsWithChildren<any>> }>> = {
    BasePage: () => import('../../base/BasePage'),
    OrderCompletePage: () => import('../../ecommerce/components/pages/orderCompletePage/OrderCompletePagePortal'),
    RecipeRouter: () => import('../../recipe/router/RecipeRouter'),
    ConnectMembershipPage: () => import('../../secureIdentifyUser/components/pages/connectMembershipPage/ConnectMembershipPage'),
    PointShopRouter: () => import('../../member/pointshop/PointShopRouter'),
    PointShopAutoCheckPage: () => import('../../member/pointshop/pages/PointShopAutoCheckPage/PointShopAutoCheckPage'),
    RecipeCampaignPage: () => import('../../recipe/pages/RecipeCampaignPage/RecipeCampaignPage'),
    GlobalSearchResultPage: () => import('../../search/components/GlobalSearchResultPage/GlobalSearchResultPage'),
    GdprExportAdminPage: () => import('../../gdprExportAdmin/pages/GdprExportAdminPage'),
    MyCoopRouter: () => import('../../myCoop/MyCoopRouter'),
};
